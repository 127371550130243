<script>
import CwIcon from "@/components/form/CwIcon.vue";
import {useSessionStore} from "@/utils/useSessionStore.js";

export default {
  name: "ModalDialog",
  components: {CwIcon},
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    cancelLabel: {
      type: String,
      default() {
        return '';
      }
    },
    confirmLabel: {
      type: String,
      default() {
        return useSessionStore().isKorean ? '확인' : 'Confirm';
      }
    },
    reject: {
      type: Function,
      default: () => {
      }
    },
    resolve: {
      type: Function,
      default: () => {
      }
    },
    duration: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      default: null
    },
    easyClose: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'confirm', 'cancel', 'open', 'update:visible'],
  data() {
    return {
      progress: 0,
      isFocused: false
    }
  },
  computed: {
    hasImageUrl() {
      return this.imageUrl !== null;
    }
  },
  mounted() {
    if (this.duration > 0) {
      this.startProgress();
    }
  },
  methods: {
    async openModal() {
      this.$emit('update:visible', true);
      return await new Promise((resolve, reject) => {
        this.$emit('open', {
          resolve,
          reject
        })
      })
    },
    async closeModal() {
      this.$emit('update:visible', false);
      try {
        await new Promise((resolve, reject) => {
          this.$emit('cancel', {
            resolve,
            reject
          })
          this.$emit('close', {
            resolve,
            reject
          })
          resolve();
        });
      } catch (e) {
        // empty
      }
    },
    async confirmAction() {

      try {
        await new Promise((resolve, reject) => {
          this.$emit('confirm', {
            resolve,
            reject
          })
          this.$emit('close', {
            resolve,
            reject
          })
        });
        this.$emit('update:visible', false);
      } catch (e) {
        // 확인을 했는데도 reject가 발생한 경우에는 다시 모달을 열어줍니다.
        this.$emit('update:visible', true);
      }
    },
    startProgress() {

      const timer = setInterval(() => {
        if (this.progress < 100) {
          if (!this.isFocused) {
            this.progress++;
          } else {
            this.progress = 100;
            clearInterval(timer);
          }
        } else {
          this.closeModal();
          clearInterval(timer);
        }
      }, this.duration * 10);  // 각 단계는 duration의 10분의 1초마다 진행됩니다.
    }
  }
}
</script>

<template>
  <transition
    name="modal"
    appear
    enter-active-class="ease-out duration-300"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 -translate-y-5"
  >
    <div
      v-if="visible"
      class="fixed inset-0 flex items-center justify-center z-30 bg-black bg-opacity-50"
      @click="easyClose ? closeModal() : null"
    >
      <template v-if="hasImageUrl">
        <div class="relative p-4">
          <img
            :src="imageUrl"
            class="w-full h-auto"
          >
          <div class="absolute top-4 right-4 m-4 z-30 text-white bg-white rounded-full bg-opacity-70 ">
            <cw-icon
              name="close"
              @click="closeModal()"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div
          id="modal-dialog"
          class="bg-white rounded-md shadow-lg w-[90%] lg:w-1/3 p-4 relative"
          @mouseenter="()=>{isFocused = true}"
          @mouseleave="()=>{isFocused =false}"
          @click.stop
        >
          <template v-if="easyClose">
            <div class="absolute cursor-pointer top-0 right-0 m-4 z-30 text-white bg-white rounded-full bg-opacity-70 ">
              <cw-icon
                name="close"
                @click="closeModal()"
              />
            </div>
          </template>
          <transition
            name="progress"
            appear
            appear-class="ease-out duration-300"
            appear-from-class="opacity-0"
            appear-to-class="opacity-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="duration > 0 && progress < 100"
              style="height: 4px; width: 100%;"
              class="progress-bar-container rounded overflow-hidden absolute top-0 left-0 right-0"
            >
              <div
                class="progress-bar bg-stone-700 bg-opacity-50"
                style="height: 100%; width: 0;"
                :style="{ width: `${progress}%` }"
              />
            </div>
          </transition>
          <h2
            v-if="title"
            class="ev-heading-3 mb-4"
          >
            {{ title }}
          </h2>
          <slot :message="message">
            <pre
              v-if="message"
              class="ev-paragraph-1"
            >{{ message }}</pre>
          </slot>
          <div class="mt-6 text-right">
            <button
              v-if="cancelLabel"
              class="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded mr-2"
              @click.stop="closeModal()"
            >
              {{ cancelLabel }}
            </button>
            <button
              v-if="confirmLabel"
              class="bg-stone-500 hover:bg-stone-700 text-white py-2 px-4 rounded"
              @click.stop="confirmAction()"
            >
              {{ confirmLabel }}
            </button>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>

<style scoped>
.no-padding-inner > #modal-dialog {
  padding: 0;
}

.bg-transparent-inner > #modal-dialog {
  background-color: transparent;
  box-shadow: none;
}
</style>
