import {defineStore} from "pinia";
import LaravelPiniaPlugin from "@/utils/LaravelPiniaPlugin.js";
import {useLocalStorage} from "@vueuse/core";


let options = {
  ...LaravelPiniaPlugin()
}

export const usePopup = defineStore('popup', {
  state: () => ({
    ...options.state,
    ...{
      url : '/api/v1/popup',
      primaryKey: 'id',
      query: {}
    },
    readIds: useLocalStorage('pinia/popup/readId', '')
  }),
  getters: {
    ...options.getters,
  },
  actions: {
    ...options.actions,
    async getActivePopup(){
      try{
        this.query = {
          ...this.query,
          active: true
        }
        await this.getPageIndex(1);
      }catch (e) {
        console.error(e);
      }
    },
    haveReadPopups(){

      if(this.getData.length === 0){
        return false;
      }

      let data = this.getData.map(item => {
        return item.id
      });

      return data.filter(id => {
        return this.isRead(id);
      }).length === data.length;
    },
    async readPopup(id){
      if(!window){
        return;
      }
      let items = this.getLocalStorageIds();
      // 이미 id 를 가진 데이터가 있다면 날짜만 업데이트
      let item = items.filter(item => {
        return item.id === id;
      })?.[0];

      if(item){
        item.read_at = new Date();
        items = items.filter(item => {
          return item.id !== id;
        }).push(item);
      }else{
        items.push({id:id, read_at: new Date()});
      }

      window.localStorage.setItem('pinia/popup/readId', JSON.stringify(items));
    },
    getLocalStorageIds(){
      if(!window){
        return [];
      }
      let items = [];
      let temp = window.localStorage.getItem('pinia/popup/readId');
      if(!temp){
        window.localStorage.setItem('pinia/popup/readId', '[]');
        return [];
      }
      items = JSON.parse(temp);
      if(!Array.isArray(items)){
        items = [];
      }
      // id를 기준으로 유니크한 배열로 만듭니다.
      items = items.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.id === item.id
        ))
      );

      if(!items.length === 0){
        items = [];
      }
      return items;
    },
    hasUnread(){

    },
    setReadId(id){
      if(!window){
        return;
      }
      let items = this.getLocalStorageIds();
      items = items.filter(item=>{
        return item.id && item.id !== id;
      });
      console.log(items, 'items', id);
      items.push({id:id, read_at: new Date()});
      console.log(items, 'items-add', id);
      // items 의 갯수가 5개 이상이면 read_at이 가장 오래된 것을 삭제합니다.
      if(items.length > 5){
        items.shift();
      }

      window.localStorage.setItem('pinia/popup/readId', JSON.stringify(items));
    },
    isRead(id){
      if(!window){
        return true;
      }
      /**
       * @typedef {{id: number, read_at: string}} readItem
       * @type {readItem[]}
       */
      let readItems = this.getLocalStorageIds();
      if(readItems.length === 0){
        return false;
      }
      /**
       * @type {{id: number, type: string, link: string}} item
       */
      const item = this.getData.filter(item => {
        return item.id === id;
      })?.[0];

      if(!item){
        return false;
      }

      const readItem = readItems.filter(item => {
        return item.id === id;
      })?.[0];

      if(!readItem){
        return false;
      }

      if(item.type === 'daily'){
        // 읽은 시간을 가져옵니다.
        const readAt = new Date(readItem.read_at);
        // 읽은 시간이 오늘인지 확인합니다.
        let readToday = readAt.getDate();
        let today = new Date().getDate();
        return readToday === today;
      }

      return readItems.filter(item => {
        return item.id === id;
      }).length > 0;

    }
  }
});
