<script setup>

import {computed, onBeforeMount, ref} from "vue";
import axios from "@/utils/axios.js";
import MobileCategoryMenu from "@/components/ui/MobileCategoryMenu.vue";
import {useSessionStore} from "@/utils/useSessionStore.js";
import {useRouter} from "vue-router";

const router = useRouter();
import {__} from "@/i18n/useLanguage.js";
import CwIcon from "@/components/form/CwIcon.vue";
import AccordionList from "@/components/ui/AccordionList.vue";

const sessionStore = useSessionStore();

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

function loginOrMypage() {
  if (sessionStore.authenticated) goTo('/my-page')
  else goTo('/login')

}

function logoutOrRegister() {
  if (sessionStore.authenticated) logout()
  else goTo('/register')
}

function goTo(url) {
  emit('update:visible', false)
  router.push(url);
}

async function logout() {
  await sessionStore.logout();
  emit('update:visible', false)
  router.push('/');
}

onBeforeMount(async () => {
  const res = await getCategories();
  categories.value = res.data.data;
  selectedItems.value.push(res.data.data);

})

const getCategories = async () => {
  return await axios.get('/api/evans-cart/categories-service')
}
const categories = ref([])
const selectedItems = ref([])
const parentCategory = ref({});

function addCatHistory(item) {
  categoryTitle.value.push(item.name);
  parentCategory.value = item;
  selectedItems.value.push(item.descendants);
}

const lastMenus = computed(() => {
  if (selectedItems.value.length === 0) return [];
  let items = selectedItems.value[selectedItems.value.length - 1];

  return items.filter((item) => item.id !== 12);
})

const saleMenus = computed(() => {
  if (categories.value.length === 0) return [];

  return categories.value.filter((item) => item.id === 12)?.[0]?.descendants || [];
});


const isOpenCategory = computed(() => {
  return selectedItems.value.length > 0;
})

function backToMain() {
  if (selectedItems.value.length > 0) {
    // 마지막 요소 삭제
    selectedItems.value.pop();
    categoryTitle.value.pop();
  }
  if (selectedItems.value.length === 0) {
    selectedItems.value = [];
    parentCategory.value = {};
    emit('update:visible', false)
  }
}

const getCurrentTitle = computed(() => {
  if (categoryTitle.value.length === 0) return 'All Products';
  return categoryTitle.value[selectedItems.value.length - 1];
})
const categoryTitle = ref(['All Products']);

function selectToMenu(menu) {
  categoryTitle.value = [];
  categoryTitle.value = [menu];
  selectedItems.value = [];
  parentCategory.value = {};
  selectedItems.value.push([...categories.value]);
}

const emit = defineEmits(["update:visible"]);

function closeSidebar() {
  emit('update:visible', false)
}

const keyword = ref('');
const submitKeyword = () => {
  if (keyword.value.length === 0) return;
  goTo({
    name: 'ProductSearch',
    query: {
      keyword: keyword.value
    }
  })
}
</script>
<template>
  <transition
    class="transition-all duration-300 print:hidden"
    enter-from-class="opacity-0 -left-full"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 -left-full"
  >
    <div
      v-show="props.visible"
      class="fixed w-full top-0 left-0 max-h-screen z-[200] overflow-auto max-w-md mx-auto"
    >
      <div class="relative max-w-lg mx-auto">
        <div
          class="w-full max-h-16 p-4 bg-stone-100 border-b border-stone-300 flex-col justify-start items-start gap-2 inline-flex sticky top-0 left-0 z-20"
        >
          <div class="self-stretch justify-start items-center gap-4 inline-flex">
            <div class="grow shrink basis-0 h-7 justify-start items-center flex">
              <img
                v-show="!isOpenCategory"
                class="w-14"
                src="/images/icons/logo-main.png"
                alt=""
              >
              <div
                v-show="isOpenCategory"
                class="w-16 h-9 py-2 justify-start items-center gap-1 inline-flex"
                @click="backToMain"
              >
                <button
                  class="w-5 h-5 relative"
                >
                  <img
                    src="/images/icons/chevron.svg"
                    alt=""
                    class="rotate-180"
                  >
                </button>
                <div class="text-center text-stone-700 text-base font-semibold leading-none">
                  Back
                </div>
              </div>
            </div>
            <div class="menu-icons justify-end items-center gap-4 flex">
              <img
                src="/images/icons/close.png"
                class="w-5 h-5 relative"
                @click="closeSidebar"
              >
            </div>
          </div>
        </div>
        <div class="z-10 w-full bg-white">
          <div class="w-full px-4 py-6 flex-col justify-start items-start gap-6 inline-flex">
            <div
              class="ev-heading-5 flex items-center justify-center gap-2 w-full"
            >
              <img
                src="/images/icons/user-white.png"
                class="w-5 h-5"
              >
              <button
                @click="logoutOrRegister"
              >
                {{ (sessionStore.authenticated) ? $t('로그아웃') : $t('회원가입') }}
              </button>
              |
              <button
                @click="loginOrMypage"
              >
                {{ (sessionStore.authenticated) ? $t('마이페이지') : $t('로그인') }}
              </button>

              <div
                class="ml-auto flex gap-1"
                @click="()=>goTo({name:'CustomerCenter'})"
              >
                <cw-icon name="phone" />
                {{ $t("고객센터") }}
              </div>
            </div>
            <div class="w-full h-12 py-4 border-b border-stone-700 justify-start items-center gap-5 inline-flex">
              <div class="grow shrink basis-0 h-5 justify-start items-center gap-5 flex">
                <input
                  v-model="keyword"
                  class="grow shrink basis-0 h-4 cw-ebony-color placeholder-stone-700 text-base font-normal leading-none"
                  @change="()=>{submitKeyword()}"
                >
                <img
                  class="w-5 h-5 relative"
                  src="/images/icons/search.svg"
                  alt=""
                  @click="submitKeyword"
                >
              </div>
            </div>
            <div
              class="self-stretch grow shrink basis-0 pt-2 flex-col justify-start items-start gap-6 flex transition-all duration-200"
            >
              <div
                class="justify-start items-center gap-3 inline-flex"
                @click="selectToMenu('All Products')"
              >
                <div class="ev-heading-1">
                  {{ $t(getCurrentTitle) }}
                </div>
                <img
                  v-if="getCurrentTitle === 'All Products'"
                  src="/images/icons/chevron.svg"
                  alt=""
                  class="w-5 h-5 relative origin-top-left "
                >
                <button
                  v-else
                  class="w-5 h-5 relative justify-self-end"
                >
                  <img
                    src="/images/icons/chevron.svg"
                    alt=""
                    class="rotate-180"
                  >
                </button>
              </div>
              <div
                v-if="isOpenCategory"
                class="w-full"
              >
                <div
                  v-if="parentCategory.id"
                  class="py-3 text-stone-700 text-lg"
                  @click="()=>{
                    router.push({
                      name: 'Category',
                      params: {
                        categoryId: parentCategory.id
                      }
                    })
                    closeSidebar();
                  }"
                >
                  {{ $t("{name} 전체보기", {name: __(parentCategory.name, parentCategory.name_en)}) }}
                </div>
                <mobile-category-menu
                  :items="lastMenus"
                  @click="addCatHistory"
                  @moved="()=>{ closeSidebar() }"
                />
              </div>
              <accordion-list>
                <template #title>
                  <div
                    class="ev-heading-1"
                  >
                    Sale
                  </div>
                </template>
                <mobile-category-menu
                  :items="saleMenus"
                  @click="addCatHistory"
                  @moved="()=>{ closeSidebar() }"
                >
                  <template #header>
                    <div
                      class="text-stone-700 text-lg"
                      @click="()=>{
                        router.push({
                          name: 'Category',
                          params: {
                            categoryId: 12
                          }
                        })
                        closeSidebar();
                      }"
                    >
                      {{ $t("Sale 전체보기") }}
                    </div>
                  </template>
                </mobile-category-menu>
              </accordion-list>
              <div class="flex flex-col gap-3">
                <div
                  class="ev-heading-1"
                  @click="()=>goTo({name:'TssaMain'})"
                >
                  TSSA
                </div>
                <div
                  class="flex items-center gap-2"
                  @click="()=>goTo({name:'TssaStudio'})"
                >
                  {{ $t("TSSA 안내") }}
                </div>
                <div
                  class="flex items-center gap-2"
                  @click="()=>goTo({name:'TssaCategoryShow'})"
                >
                  {{ $t("수강신청") }}
                </div>
                <div
                  class="flex items-center gap-2"
                  @click="()=>goTo({name:'TssaBooks'})"
                >
                  {{ $t("수업교재") }}
                </div>
                <div
                  class="flex items-center gap-2"
                  @click="()=>goTo({name:'TssaNotice'})"
                >
                  {{ $t("공지사항") }}
                </div>
              </div>
              <div
                class="ev-heading-1"
                @click="()=>goTo({name:'Diy'})"
              >
                Contents
              </div>
              <div
                class="ev-heading-1"
                @click="()=>goTo({name:'Promotion'})"
              >
                Promotion
              </div>
              <div
                class="ev-heading-1 !text-[#ff5733]"
                @click="()=>goTo({name:'CustomLanding'})"
              >
                Custom Fragrance
              </div>


              <!--
              <div class="ev-heading-3">
                WHAT’S NEW
              </div>
              <div class="ev-heading-3">
                BEST
              </div>
              <div class="ev-heading-3">OUR CHOICE</div>
              <div class="ev-heading-3">
                SALE
              </div>
              -->
            </div>
            <div class="flex-col justify-start items-start gap-4 flex">
              <div
                class="text-2xl"
                @click="()=>goTo({name:'CustomerCenter'})"
              >
                Customer Service
              </div>
              <div
                class="ev-heading-5"
                @click="()=>goTo({name:'CustomerProductReview'})"
              >
                {{ $t("상품후기") }}
              </div>
              <div
                class="ev-heading-5"
                @click="goTo({name:'CustomerProductInquiry'})"
              >
                {{ $t("상품문의") }}
              </div>
              <div
                class="ev-heading-5"
                @click="()=>goTo({name:'CustomerFaq'})"
              >
                {{ $t("자주묻는질문") }}
              </div>
              <div
                class="ev-heading-5"
                @click="()=>goTo({name:'CustomerCenter'})"
              >
                {{ $t("주문/배송문의") }}
              </div>
              <div
                class="ev-heading-5"
                @click="()=>goTo({name:'OfflineStore'})"
              >
                {{ $t("매장안내") }}
              </div>
            </div>
            <div class="flex-col justify-start items-start gap-4 flex">
              <div
                class="ev-heading-5"
                @click="goTo({
                  name: 'CustomerWholesaleApply'
                })"
              >
                {{ $t("도매신청") }}
              </div>
              <div
                class="ev-heading-5"
                @click="goTo({
                  name: 'DealerApply'
                })"
              >
                {{ $t("딜러모집") }}
              </div>
            </div>
            <div class="self-stretch h-px border border-stone-300" />
            <div class="justify-start items-start gap-3 inline-flex">
              <div class="w-7 h-7 relative">
                <img
                  src="/images/icons/sns-insta.svg"
                  alt=""
                >
              </div>
              <div class="w-7 h-7 relative">
                <img
                  src="/images/icons/sns-kakao.svg"
                  alt=""
                >
              </div>
              <div class="w-7 h-7 relative">
                <img
                  src="/images/icons/sns-facebook.svg"
                  alt=""
                >
              </div>
              <div class="w-7 h-7 relative">
                <img
                  src="/images/icons/sns-youtube.svg"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.menu-icons img {
  cursor: pointer;
}

</style>
