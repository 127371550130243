import {createMemoryHistory, createRouter, createWebHistory} from 'vue-router'
import mypage from "@/routes/mypage/index.js";
import {$dialog} from "@/utils/pluginDialog.js";
import {useSessionStore} from "@/utils/useSessionStore.js";
import {$t} from "@/i18n/useLanguage.js";
import {isDesktop} from "@/plugins/ScreenHelper.js";

// Determine if we're running on server or client
const desktop = isDesktop();
// Use memory history for SSR and web history for client

// import AdminRoutes from "@/routes/admin/index.js";
export const routes = [
  {
    path: '',
    name: 'HomeWithoutSlash',
    component: () => import('./HomePage.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('./HomePage.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('./LoginPage.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./RegisterPage.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('./ForgotPasswordPage.vue')
  },
  {
    path: '/shop/reset',
    name: 'ResetPassword',
    component: () => import('./ResetPasswordPage.vue')
  },
  {
    path: '/tssa',
    name: 'TssaLayout',
    component: () => import('@/routes/tssa/TssaLayoutPage.vue'),
    children: [
      {
        name: 'TssaMain',
        path: '',
        component: () => import('@/routes/tssa/TssaMainPage.vue'),
      },
      {
        name: 'TssaAbout',
        path: 'about',
        component: () => import('@/routes/tssa/TssaAboutPage.vue'),
      },
      {
        name: 'TssaClassProduct',
        path: 'class-product',
        component: () => import('@/routes/tssa/TssaClassProductPage.vue'),
      },
      {
        name: 'TssaBooks',
        path: 'books',
        component: () => import('@/routes/tssa/TssaBooksPage.vue'),
      },
      {
        name: 'TssaNotice',
        path: 'notice',
        component: () => import('@/routes/tssa/TssaNoticePage.vue'),
      },
      {
        name: 'TssaNoticeEdit',
        path: 'notice/:id/edit',
        component: () => import('@/routes/tssa/TssaNoticeEditPage.vue'),
        props: true
      },
      {
        name: 'TssaNoticeShow',
        path: 'notice/:id',
        component: () => import('@/routes/tssa/TssaNoticeShowPage.vue'),
        props: true
      },
      {
        name: 'TssaCategoryShow',
        path: 'category',
        component: () => import('@/routes/tssa/TssaCategoryShowPage.vue'),
      },
      {
        name: 'TssaStudio',
        path: 'studio',
        component: () => import('@/routes/tssa/TssaStudioPage.vue'),
      },
      {
        name: 'TssaProductShow',
        path: 'product/:productId',
        component: () => import('@/routes/tssa/TssaProductShowPage.vue'),
        props: true
      },
    ]
  },
  {
    path: '/contents',
    name: 'Contents',
    children: [
      {
        path: 'diy',
        name: 'Diy',
        component: () => import('./contents/DiyPage.vue')
      },
      {
        path: 'diy/:id',
        name: 'DiyShow',
        component: () => import('./contents/DiyShowPage.vue'),
        props: true
      },
      {
        path: 'company',
        name: 'Company',
        component: () => import('./contents/CompanyPage.vue')
      },
      {
        name: 'ContentSelect',
        path: 'select/:id',
        component: () => import('./contents/ContentSelectPage.vue'),
        props: true
      },
      {
        path: 'store',
        name: 'OfflineStore',
        component: () => import('./contents/OfflineStorePage.vue')
      },
      {
        name: 'Privacy',
        path: 'privacy',
        component: () => import('./contents/PrivacyPage.vue'),
      },
      {
        name: "Promotion",
        path: 'promotion',
        component: () => import('./contents/PromotionPage.vue'),
        props: true
      },
      {
        name: "PromotionShow",
        path: 'promotion/:id',
        component: () => import('./contents/PromotionShowPage.vue'),
        props: true
      }
    ]
  },
  {
    path: '/customer',
    name: 'Customer',
    children: [
      {
        path: 'center',
        name: 'CustomerCenter',
        component: () => import('./customer/CustomerCenterPage.vue'),
      },
      {
        path: 'notice',
        name: 'CustomerNotice',
        component: () => import('./customer/NoticePage.vue')
      },
      {
        path: 'notice/:id/edit',
        name: 'CustomerNoticeEdit',
        component: () => import('./customer/NoticeEditPage.vue'),
        props: true
      },
      {
        path: 'notice/:id',
        name: 'CustomerNoticeShow',
        component: () => import('./customer/NoticeShowPage.vue'),
        props: true
      },
      {
        path: 'wholesale-apply/:id/show',
        name: 'CustomerWholesaleApplyShow',
        component: () => import('./customer/WholesaleApplyShowPage.vue'),
        props: true
      },
      {
        path: 'wholesale-apply/:id/edit',
        name: 'CustomerWholesaleApplyEdit',
        component: () => import('./customer/WholesaleApplyEditPage.vue'),
      },
      {
        path: 'wholesale-apply',
        name: 'CustomerWholesaleApply',
        component: () => import('./customer/WholesaleApplyPage.vue')
      },
      {
        path: 'faq',
        name: 'CustomerFaq',
        component: () => import('./customer/FaqPage.vue')
      },
      {
        path: 'faq/:id/edit',
        name: 'CustomerFaqEdit',
        component: () => import('./customer/FaqEditPage.vue'),
        props: true
      },
      {
        path: 'product-inquiry',
        name: 'CustomerProductInquiry',
        component: () => import('./customer/ProductInquiryPage.vue')
      },
      {
        name: 'CustomerProductReview',
        path: 'product-review',
        component: () => import('./customer/ProductReviewPage.vue'),
      },
      {
        name: 'DealerApply',
        path: 'dealer-apply',
        component: () => import('./customer/DealerApplyPage.vue'),
      },
      {
        name: 'DocumentBoard',
        path: 'document-board',
        component: () => import('./customer/DocumentBoardPage.vue'),
      },
      {
        name: 'DocumentBoardShow',
        path: 'document-board/:id',
        meta: {
          guard: true
        },
        component: () => import('./customer/DocumentBoardShowPage.vue'),
        props: true
      },
      {
        name: 'DocumentBoardEdit',
        path: 'document-board/:id/edit',
        component: () => import('./customer/DocumentBoardEdit.vue'),
        props: true
      },
      {
        name: 'ShipmentCostPage',
        path: 'shipment-cost',
        component: () => import('./customer/ShipmentCostPage.vue'),
      }
    ]
  },
  {
    path: '/shop',
    name: 'Shop',
    children: [
      {
        name: "Category",
        path: "category/:categoryId",
        component: () => import('./shop/CategoryShowPage.vue'),
        props: true
      },
      {
        name: 'Product',
        path: 'product/:productId',
        component: () => import('./shop/ProductShowPage.vue'),
        props: true
      },
      {
        name: 'Quotation',
        path: 'quotation',
        meta: {
          blank: true
        },
        component: () => {
          if (desktop) {
            return import('./shop/QuotationPageDesktop.vue');
          } else {
            return import('./shop/QuotationPage.vue');
          }
        },
      },
      {
        name: 'Cart',
        path: 'cart',
        component: () => {
          return import('./shop/CartPage.vue');
        }
      },
      {
        name: 'OrderForm',
        path: 'order-form',
        component: () => {
          if (desktop) {
            return import('./shop/OrderFormPageDesktop.vue');
          } else {
            return import('./shop/OrderFormPage.vue');
          }
        },
      },
      {
        name: 'OrderConfirm',
        path: 'order/:orderId/confirm',
        component: () => {
          if (desktop) {
            return import('./shop/OrderConfirmPageDesktop.vue');
          } else {
            return import('./shop/OrderConfirmPage.vue');
          }
        },
        props: true
      },
      {
        name: 'PaymentComplete',
        path: 'payment_confirm', // 구버전 호환성 유지
        component: () => import('./shop/PaymentCompletePage.vue'),
      },
      {
        name: 'OrderShow',
        path: 'my_order_view',
        component: () => import('./mypage/MyPageOrderShow.vue'),
        props: (route) => {
          if (route.query.errorMessage) {
            $dialog($t(route.query.errorMessage))
          }
          return {orderId: route.query.od_id}
        }
      },
      {
        name: "ProductSearch",
        path: "search",
        component: () => import('./shop/ProductSearchPage.vue')
      },
      {
        name: "ProductNewItem",
        path: "new-item",
        component: () => {
          return import('./shop/ProductNewItemPage.vue');
        },
      }
    ]
  },
  {
    path: '/custom/fragrance_service',
    name: 'CustomFragrance',
    component: () => import('./custom/CustomFragrancePage.vue')
  },
  {
    path: '/custom/fragrance',
    name: 'CustomLanding',
    component: () => import('./custom/CustomFragranceLanding.vue')
  },
  ...mypage,
  {
    path: '/shop/list',
    redirect: to => {
      //?ca_id&ca_id_new=247
      return {path: '/shop/category/' + to.query.ca_id_new}
    }
  },
  {
    path: '/info/select/:id',
    redirect: to => {
      return {path: '/contents/select/' + to.params.id}
    }
  },
  {
    path: '/info/do-it-yourself/:id',
    redirect: to => {
      return {path: '/contents/diy/' + to.params.id}
    }
  },
  {
    name: 'redirect1',
    path: '/shop/workshop/notice',
    redirect: () => {
      return {name: 'TssaNotice'}
    }
  },
  {
    path: '/board/document/post/:id',
    redirect: to => {
      return {name: 'DocumentBoardShow', params: {id: to.params.id}}
    }
  },
  {
    path: '/shop/board/document/post/:id',
    redirect: to => {
      return {name: 'DocumentBoardShow', params: {id: to.params.id}}
    }
  },
  {
    path: '/shop/item.php', // ?it_id=8329
    redirect: to => {
      if (!to.query?.it_id)
        return {name: 'Home'}

      return {name: 'Product', params: {productId: to.query.it_id}}
    }
  },
  {
    path: '/shop/register.php',
    redirect: () => {
      return {name: 'Register'}
    }
  },
  {
    path:'/shop/qa_list.php',
    redirect: () => {
      return {name: 'CustomerFaq'}
    }
  },
  {
    path:'/shop/qa_view.php',
    redirect: () => {
      return {name: 'CustomerFaq'}
    }
  },
  {
    name: 'ContentView',
    path: '/contents/:path',
    component: () => import('@/routes/ContentsShowPage.vue'),
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/routes/NotFoundPage.vue')
  }
];
export const createRouterSSR = ()=> {

  const router = createRouter({
    history: import.meta.env.SSR
      ? createMemoryHistory("/")
      : createWebHistory("/"),
    routes: routes,

    scrollBehavior(to, from, savedPosition) {
      // 라우트에 # 이 있다면 해당 위치로 이동
      if (savedPosition) {
        // 뒤로/앞으로 버튼을 클릭했을 때는 저장된 스크롤 위치로 이동
        return savedPosition
      }

      // 경로는 동일하고 param만 변경되었는지 확인
      if (to.path === from.path && to.fullPath !== from.fullPath) {
        return {x: 0, y: 0};
      }

      let top = 0;
      if (to.hash) {
        top = document.getElementById(to.hash.substring(1)).offsetTop;
      }

      // 그 외에는 페이지의 맨 위로 이동
      if (top > 70) {
        top = top - 70;
      }
      return {top: top}

    }
  });

  router.onError((error, to) => {
    console.error('router-error', error);

    // 무한 반복 방지
    if (to.query.reload) {
      $dialog($t('페이지를 불러오지 못했어요. 새로고침 해 주세요.'));
      return;
    }
    const messages = [
      'Unable to preload CSS for',
      'Importing a module script failed.',
      'Failed to fetch dynamically imported module'
    ]
    if (messages.some(msg => error.message.includes(msg))) {
      window.location.href = to.fullPath + '?reload=1'
    }
  });
  router.beforeEach(async (to, from, next) => {

    const sessionStore = useSessionStore();

    if (to.name === 'Login') {
      if (!sessionStore.authenticated) {
        return next();
      } else {
        return next({name: 'MyPage'});
      }
    }
    // my-page 으로 시작하는 페이지는 로그인이 필요함
    if ((to.path.startsWith('/my-page') || to.meta?.guard) && !sessionStore.authenticated) {
      await $dialog($t('로그인이 필요해요.'))
      return next({name: 'Login', query: {redirect: to.fullPath}})
    }

    return next()
  })

  return router;
}

let router = createRouterSSR();
export default router;
