
import {defineStore} from "pinia";
import LaravelPiniaPlugin from "@/utils/LaravelPiniaPlugin.js";


let options = {
  ...LaravelPiniaPlugin()
}

export const useClickLog = defineStore('click-log', {
  state: () => ({
    ...options.state,
    ...{
      url : '/api/v1/click',
      primaryKey: 'id',
      query: {}
    }
  }),
  getters: {
    ...options.getters,
  },
  actions: {
    ...options.actions,
  }
});
