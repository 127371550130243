<template>
  <MobileSidebar v-model:visible="sideBar" />
  <div
    class="mx-auto w-full sticky top-0 left-0 z-[20] h-16"
    :class="(sideBar) ? ['overflow-hidden']:[]"
  >
    <div
      class="h-16 print:hidden bg-opacity-90 z-10 w-full max-h-16 p-4 bg-stone-100 border-b border-stone-300 flex-col justify-start items-start gap-2 inline-flex"
    >
      <div class="self-stretch justify-start items-center gap-4 inline-flex">
        <div class="grow shrink basis-0 h-7 justify-start items-center flex">
          <router-link to="/">
            <img
              class="w-14"
              src="/images/icons/logo-main.png"
              alt=""
            >
          </router-link>
        </div>
        <div class="menu-icons justify-end items-center gap-4 flex">
          <img
            src="/images/icons/cart.png"
            class="w-5 h-5"
            alt=""
            @click="()=>router.push({
              name: 'Cart'
            })"
          >
          <router-link to="/my-page">
            <div class="relative">
              <img
                src="/images/icons/user.png"
                class="w-5 h-5"
                alt=""
              >
              <div
                v-if="notification.getUnreadCount"
                class="w-4 h-4 -right-1.5 -top-1 text-white text-xs bg-red-400 rounded-full justify-center items-center flex absolute"
              >
                {{ notification.getUnreadCount }}
              </div>
            </div>
          </router-link>
          <img
            alt=""
            src="/images/icons/globe.png"
            class="w-5 h-5 relative"
            @click="changeLanguage"
          >
          <img
            alt=""
            src="/images/icons/menubar.png"
            class="w-5 h-5 relative"
            @click="showSideBar()"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import {useRouter} from "vue-router";
import {computed, onMounted} from "vue";
import {$confirm} from "@/utils/pluginDialog.js";
import {useSessionStore} from "@/utils/useSessionStore.js";
import MobileSidebar from "@/components/ui/MobileSidebar.vue";
import {useNotification} from "@/utils/useNotification.js";

const props = defineProps({
  sideBar: Boolean
});
const emit = defineEmits(['update:sideBar']);

const sideBar = computed({
  get: () => props.sideBar,
  set: (value) => {
    emit('update:sideBar', value);
  }
});
const showSideBar = () => {
  sideBar.value = !sideBar.value;
};
const router = useRouter();
const sessionStore = useSessionStore();
const notification = useNotification();
async function changeLanguage() {
  // 브라우저에서 locale 코드 읽기
  try {

    if(sessionStore.isKorean) {
      await $confirm('Do you want to change language and currency?')
      await sessionStore.changeLanguage('en', 'usd')
    } else {
      await $confirm('언어와 통화를 변경하시겠습니까?')
      await sessionStore.changeLanguage('kr', 'krw')
    }
    //await sessionStore.init();
    window.location.reload();
  } catch (e) {
    // 사용자가 취소한 경우
  }
}

onMounted(() => {
  if(sessionStore.authenticated){
    notification.get();
  }
});

</script>
<style scoped>
.menu-icons img {
  cursor: pointer;
}

</style>
