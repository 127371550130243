<template>
  <modal-dialog
    v-if="Popup.getData?.length > 0"
    v-model:visible="hasActivePopup"
    class="no-padding-inner bg-transparent-inner popup-dialog"
    @confirm="confirmHandler"
    @cancel="confirmHandler"
  >
    <swiper
      :autoplay="true"
      :pagination="{
        clickable: true,
        type: 'fraction'
      }"
      :space-between="0"
      :speed="500"
      :loop="true"
      :effect="'cards'"
      :modules="[Navigation, Pagination, Autoplay, EffectCards]"
      display="horizontal"
      :slides-per-view="1"
      class="w-full"
    >
      <swiper-slide
        v-for="popup in popups || []"
        :key="popup.id"
      >
        <img
          class="cursor-pointer w-[90%] object-cover rounded-lg mx-auto"
          :src="__(popup?.thumbnail_ko, popup?.thumbnail_en)"
          @click="moveToLink(popup)"
        >
      </swiper-slide>
    </swiper>
  </modal-dialog>
</template>
<script setup>
import {usePopup} from "@/utils/usePopup.js";
import {useSessionStore} from "@/utils/useSessionStore.js";
import {computed, onMounted, ref} from "vue";
import {__} from "@/i18n/useLanguage.js";

import {Autoplay, EffectCards, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/vue";
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';


import {useClickLog} from "@/utils/useClickLog.js";

const Popup = usePopup();
const popups = computed(() => Popup.getData);
const sessionStore = useSessionStore();
const ClickLog = useClickLog();

async function confirmHandler({resolve}) {
  await resolve();
  setReadAllPopups();
}
async function moveToLink(popup) {
  setReadAllPopups();
  await ClickLog.store({
    class: 'App\\Resources\\Popups',
    id: popup.id,
  });
  if (popup?.link) {
    if (sessionStore.isKorean) {
      window.location.href = popup.link;
    } else {
      window.location.href = popup.link_en;
    }

  }
}

async function initPopup() {
  await Popup.getActivePopup();

  if( !Popup.haveReadPopups() ){
    hasActivePopup.value = true;
  }
}

const hasActivePopup = ref(false);

function setReadAllPopups() {
  Popup.getData.forEach(async (popup) => {
    await Popup.setReadId(popup.id);
  });
  hasActivePopup.value = false;
}

onMounted(() => {
  initPopup();
});
</script>
<style>
.menu-icons img {
  cursor: pointer;
}

:root{
  --swiper-pagination-fraction-color: #fff;
}
.popup-dialog .swiper-pagination-fraction{
  @apply text-white border px-1 bg-stone-600 bg-opacity-80 rounded p-1 relative w-[3rem] mx-auto;
}

.popup-dialog .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
</style>
